/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

//import prims code highlighting theme
require("prismjs/themes/prism-tomorrow.css");

//import webfonts css
require('typeface-roboto');
require('typeface-roboto-mono');
//include styles
require('./src/styles/main.css');